<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<h3>CASE Network 2</h3>

	<p><b>CASE Network 2</b>, hosted by Common Good Learning Tools in collaboration with 1EdTech, serves as a clearinghouse for CASE frameworks published by states and other standards issuing agencies, so that systems can use the APIs from <a href="https://casenetwork.1edtech.org" target="_blank">casenetwork.1edtech.org</a> to retrieve competency frameworks from multiple issuing agencies, rather than having to go to each agency’s individual API url for each agency’s frameworks. Once an issuing agency has registered with CASE Network (at no cost to the agency), the CASE Network system:</p>
	<ul>
		<li>retrieves the agency’s frameworks using the agency’s CASE publishing tool’s APIs,</li>
		<li>“mirrors” the frameworks on CASE Network,</li>
		<li>automatically checks for updates to frameworks every other day,</li>
		<li>and updates the frameworks’ mirrors if necessary.</li>
	</ul>
	<p>See the <span v-html="link('case', 'What is CASE?')"></span> for a detailed explanation of how CASE Network 2 fits into the broader CASE ecosystem.</p>
</div></template>

<script>
	import HelpMixin from './HelpMixin'
	export default {
		mixins: [HelpMixin],
	}
</script>

<style lang="scss">
</style>
	